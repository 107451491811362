html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  background-color: white;
  background-image: url(./assets/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-family: Monserrat, sans-serif;
}

.navlogo {
  max-width: 6%;
  max-height: 8%;
  margin: 0;
  padding: 0;
}

.MuiButton-textSizeLarge {
  font-size: 1.5rem !important;
}

/* home page elements */
.banner img {
  max-width: 100%;
}
.section {
  width: 80%;
  margin: 3rem auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  background: rgba(232, 244, 255, 0.26);
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.container-media {
  padding: 2rem 1rem 2rem 2rem;
  display: flex;
  align-items: center;
}
.container-media img {
  width: 100%;
}
.container-txt {
  font-size: 2.5rem;
  line-height: normal;
  padding: 0 0 0 1rem;
}

.container-txt h1 {
  line-height: 4.5rem;
}

#section-3 {
  width: 65%;
  margin: auto;
  padding-top: 2rem;
  font-size: 2rem;
  text-align: center;
}

#section-3 p {
  padding: 3rem 0 2rem 0;
  width: 60%;
  margin: auto;
}

#section-3 h1 {
  line-height: 7rem;
  width: 100%;
}

.btn {
  font-size: 4rem;
  border-radius: 16px;
  background: rgba(80, 87, 97, 0.623);
  border: none;
  padding: 1.5rem;
  color: rgba(248, 242, 242, 0.85);
  cursor: pointer;
}
.btn:hover {
  color: rgba(17, 17, 17, 0.705);
  background: rgba(175, 175, 175, 0.609);
}
a {
  text-decoration: none;
  color: black;
  font-size: large;
}

a:hover {
  text-decoration: underline;
}

iframe {
  width: 100%;
  height: 100%;
}

/* sign up */
#logo {
  height: 300px;
  width: 300px;
}

input {
  margin-top: 2rem;
  width: 100%;
  font-size: 30px;
  border-radius: 8px;
  background-color: rgba(9, 13, 223, 0.226);
  color: white;
  border-style: solid;
  border: grey;
}

::placeholder {
  color: rgb(219, 216, 216);
}

/* login */
.form-bg {
  width: 100%;
  margin: 0;
  padding: 1rem 0 1rem 0;
  background-color: rgba(240, 248, 255, 0.719);
  border-radius: 25px;
}
#link-to {
  margin-top: 2rem;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  /* border: rebeccapurple solid 2px; */
  color: rgba(245, 245, 245, 0.801);
}

#sign-up {
  margin: 2rem;
  font-size: 30px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.746);
  /* border: lightcyan solid 2px; */
}

.performanceChart {
  margin: 3vh auto 5vh auto;
  width: 90vw;
  height: 40vh;
}

.allocationChart {
  margin: 0vh auto 5vh auto;
  width: 90%;
}

.pageLink {
  width: 5%;
  height: 5vh;
  display: flex;
  align-items: center;
}

.pageLink a:hover {
  background-color: #fff;
}

.pageLinkContainer {
  width: 98%;
  margin: 3vh auto 3vh auto;
  border-radius: 5px;
}

.pageLink a {
  background-color: #c5c5c7d5;
  color: black;
  height: 100%;
  text-align: center;
  line-height: 5vh;
  text-decoration: none;
  width: 100%;
}

.definition-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 3vh auto 3vh auto;
  padding: 3vh;
  width: 90%;
  border: 2px solid black;
}

.definition-section h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.definition-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.definition-links li {
  margin-right: 2vw;
}

.definition-links ul {
  list-style-type: none;
  padding: 0;
}

.definition-link {
  border-radius: 5px;
  background-color: #c9d5ec;
  color: #fff;
  width: 55%;
}

/* tutorial */
.chart {
  min-height: 35vh;
}

.tutorial-section {
  background-color: rgba(245, 245, 245, 0.664);
  border-radius: 10px;
  padding: 1rem;
  width: 80%;
  margin: 5vh auto 2vh auto;
}

.security-metrics-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 3vh;
}

.security-metric {
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 0.658);
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-size: 1.25rem;
}

.security-metric .label {
  margin-left: 2%;
}

.security-metric .value {
  margin-right: 2%;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.security-metric a {
  text-decoration: underline;
}
